
export default class CalendarService {

    async bookMeeting(userId, key, payload) {
        return await window.appData.$http.post('/public/book_meeting/' + userId + '/' + key, payload)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async getTimeslots(userId, key, start, end) {
        return await window.appData.$http.get('/public/timeslots/' + userId + '/' + key, {
            params: {
                date: start,
                to_date: end,
            }
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }
}