<template>
	<div class="p-fluid">
		<div v-if="step === 0" class="p-fluid">
			<p class="text-muted">
				<b>Attention</b> : Une fois le rendez-vous confirmé il ne vous sera plus possible de le modifier.
			</p>

			<div v-if="!contactExist" class="p-fluid p-mb-3">
				<div v-if="profile.misc.rgpd == 0" class="p-fluid">
					<p>
						{{ company }} a besoin des coordonnées que vous nous fournissez pour vous contacter au sujet de nos
						produits et services. Vous pouvez vous désabonner de ces communications à tout moment.<br>
						En cliquant sur "Accepter" vous acceptez ces conditions.
					</p>
				</div>
				<div v-else-if="profile.misc.rgpd == 1" class="p-fluid">
					<p>
						{{ company }} s'engage à protéger et à respecter votre vie privée. Nous n'utiliserons vos
						données personnelles que pour administrer votre compte et vous fournir les produits et
						services demandés. Nous aimerions vous contacter ponctuellement au sujet de nos produits et services,
						ainsi que d'autres contenus susceptibles de vous intéresser.
					</p>
					<div class="p-text-center p-mb-3">
						<u>Vous pouvez vous désabonner de ces communications à tout moment.</u>
					</div>
					<div class="p-form p-mt-2 p-mb-2">
						<div class="p-field-checkbox">
							<Checkbox id="data_process" v-model="dataProcessing" :binary="true" />
							<label for="data_process"><b>J'accepte de recevoir d'autres communications de {{ company }}.</b></label>
						</div>
					</div>
					<p>
						En cliquant sur "Accepter" ci-dessous, vous autorisez {{ company }} à stocker et traiter
						les données personnelles soumises ci-dessus afin de vous fournir le contenu demandé.
					</p>
				</div>

				<div v-else-if="profile.misc.rgpd == 2" class="p-fluid">
					<p>
						{{ company }} s'engage à protéger et à respecter votre vie privée. Nous n'utiliserons vos
						données personnelles que pour administrer votre compte et vous fournir les produits et
						services demandés. Nous aimerions vous contacter ponctuellement au sujet de nos produits et services,
						ainsi que d'autres contenus susceptibles de vous intéresser.
					</p>
					<div class="p-text-center p-mb-3">
						<u>Vous pouvez vous désabonner de ces communications à tout moment.</u>
					</div>
					<div class="p-form p-mt-2 p-mb-2">
						<div class="p-field-checkbox p-mb-3">
							<Checkbox id="data_process" v-model="dataProcessing" :binary="true" />
							<label for="data_process"><b>J'accepte de recevoir d'autres communications de {{ company }}.</b></label>
						</div>

						<p>Afin de vous fournir le contenu demandé, nous devons stocker et traiter vos données personnelles.
							Si vous nous autorisez à stocker vos données personnelles à cette fin, cochez la case ci-dessous.</p>
						<div class="p-field-checkbox">
							<Checkbox id="data_storage" v-model="dataStorage" :binary="true" />
							<label for="data_storage"><b>J'accepte que {{ company }} stocke et traite mes données personnelles.</b></label>
						</div>
					</div>
				</div>
			</div>

			<div class="p-fluid p-d-flex p-align-center p-jc-center">
				<ProgressSpinner v-if="loading"></ProgressSpinner>
				<Button v-if="!loading" label="Annuler"
						@click.prevent="emitClose()"
						class="p-button p-button-danger p-mr-2"></Button>
				<Button v-if="!loading"  label="Accepter"
						:disabled="!rgpdAcceptance"
						@click.prevent="step++"
						class="p-button"></Button>
			</div>

		</div>
		<div v-else-if="step === 1" class="p-fluid">
			<p class="text-muted">
				Veuillez vérifier et compléter vos informations de contact :
			</p>
			<div class="p-form">
				<div class="p-field">
					<label for="email">E-mail *</label>
					<InputText v-model="contactData.email" disabled id="email" type="email" />
				</div>

				<div v-if="!contactExist" class="p-field">
					<label for="name1">Nom *</label>
					<InputText v-model="contactData.lastname"  id="name1" type="text" />
				</div>

				<div v-if="!contactExist" class="p-field">
					<label for="firstname">Prénom *</label>
					<InputText v-model="contactData.firstname"  id="firstname" type="text" />
				</div>

				<div v-if="!contactExist" class="p-field">
					<label for="tel">Téléphone *</label>
					<InputText v-model="contactData.tel"  id="tel" type="tel" />
				</div>

				<div v-if="!contactExist" class="p-field">
					<label for="company">Société</label>
					<InputText v-model="contactData.company"  id="company" type="text" />
				</div>
			</div>
			<div  class="p-fluid p-d-flex p-align-center p-jc-center">
				<Button label="Annuler"
						@click.prevent="emitClose()"
						class="p-button p-button-danger p-mr-2"></Button>
				<Button label="Continuer" :disabled="isNotValidCoordinates"
						@click.prevent="step++"
						class="p-button"></Button>
			</div>
		</div>
		<div v-else-if="step === 2" class="p-fluid">
			<p class="text-muted">Laisser un message à votre interlocuteur : </p>
			<div class="p-form">
				<div class="p-field">
					<Textarea  v-model="contentStr"
							   placeholder="Votre message ici ..."
							   rows="5" cols="30" :autoResize="true" style="width:100%"/>
				</div>
			</div>
			<div  class="p-fluid p-d-flex p-align-center p-jc-center">
				<Button label="Modifier"
						@click.prevent="--step"
						class="p-button p-mr-2"></Button>
				<Button label="Continuer"
						@click.prevent="step++"
						class="p-button"></Button>
			</div>
		</div>
		<div v-else-if="step === 3" class="p-fluid">
			<div class="p-fluid p-text-center p-mb-2">
				<p><b>{{ isoRangeToHumanRange(targetSlot.start_dt, targetSlot.end_dt) }}</b></p>
			</div>
			<p class="text-muted p-mb-4">
				<small>Vous recevrez un e-mail de confirmation lorsque le rendez vous sera validé par votre interlocuteur.</small>
			</p>
			<div  class="p-fluid p-d-flex p-align-center p-jc-center">
				<Button label="Modifier"
						@click.prevent="--step" :disabled="loading"
						class="p-button p-mr-2"></Button>
				<Button label="Valider mon rendez-vous !"
						@click.prevent="bookMeeting" :disabled="loading"
						class="p-button p-button-success"></Button>
			</div>
		</div>
	</div>
</template>

<script>
import CrmService from "../../../service/CrmService";
import ProgressSpinner from 'primevue/progressspinner';
import calendarMixin from "../../../mixins/calendarMixin";
import CalendarService from "../../../service/CalendarService";

export default {
	data(){
		return {
			data: undefined,
			loading: false,
			step: 0,
			contactExist: false,
			contentStr: '',
			rgpdValue: false,
			dataProcessing: false,
			dataStorage: false,
			contactData: {
				email: '',
				tel: '',
				firstname: '',
				lastname: '',
				company: '',
			},
			source: undefined,
		}
	},
	props: {
		uid: {},
		ukey: {},
		profile: {},
		targetSlot: {},
		email: {},
	},
	created(){
		console.log(this.ukey,this.uid, this.targetSlot);
		this.crm = new CrmService();
		this.calendar = new CalendarService();
		this.source = this.$route.query.utm_source;
	},
	mounted(){
		this.checkEmail();
		this.contactData.email = this.email;
	},
	methods: {
		bookMeeting(){
			this.loading = true;

			//if(!isset($parameters['userData'],$parameters['token'],$parameters['guid'], $parameters['content'],
			//$parameters['rgpdAcceptance'], $parameters['targetSlot'])){


			window.appData.$main.executeRecaptcha('book_meeting')
					.then(token => {

						const payload = {
							tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
							userData: this.contactData,
							token: token,
							guid: this.profile.misc.guid_req,
							content: this.contentStr,
							rgpdAcceptance: this.profile.misc.rgpd,
							targetSlot: this.targetSlot,
							utm_source: this.source,
						};

						this.calendar.bookMeeting(this.uid, this.ukey, payload)
								.then(res => {
									if(res.data.error){
										throw new Error(res.data.message);
									}
									this.$toast.add({severity:'success', summary: 'Votre demande à été prise en compte',
										detail: 'Vous recevrez un e-mail de confirmation lorsque le rendez vous sera validé par votre interlocuteur.',
										life: 5000});
									this.emitClose(true);
								})
								.catch(err => {
									this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
									this.emitError();
								})
								.finally(() => {
									this.loading = false;
								})
					})
					.catch(err => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						this.emitError();
					})
			;
		},
		emitClose(success = false){
			this.$emit('close', success);
		},
		emitError(){
			this.$emit('errored', undefined);
		},
		checkEmail(){
			this.loading = true;
			window.appData.$main.executeRecaptcha('check_email')
					.then(token => {
						this.crm.checkEmail(this.uid, this.ukey, token, this.email)
								.then(res => {
									this.contactExist = res.data.is_contact;
								})
								.catch(err => {
									this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
								})
								.finally(() => {
									this.loading = false;
								})
					})
					.catch(err => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
			;
		},
	},
	computed: {
		rgpdAcceptance(){
			if(this.contactExist){
				return true;
			} else {
				if(this.profile.misc.rgpd == 1){
					return true;
				} else if(this.profile.misc.rgpd == 2){
					return this.dataProcessing === true && this.dataStorage === true;
				} else {
					return this.rgpdValue;
				}
			}
		},
		company(){
			if(this.profile.profile.company !== ''){
				return this.profile.profile.company;
			} else {
				return this.profile.profile.firstname + ' ' + this.profile.profile.lastname;
			}
		},
		isNotValidCoordinates(){
			if(this.contactExist){
				return false;
			} else {
				if(this.contactData.tel === '' || this.contactData.email === '' ||
						this.contactData.firstname === '' || this.contactData.lastname === ''){
					return true;
				}
				return false;
			}
		}
	},
	mixins: [calendarMixin],
	components: {
		ProgressSpinner,
	}
}
</script>

email: '',
tel: '',
firstname: '',
lastname: '',
company: '',

<style scoped>

</style>
