
export default class CrmService {

    async exchangeContact(userId, key, payload) {
        return await window.appData.$http.post('/public/exchange/' + userId + '/' + key, payload)
            .then(res => {
                if(res.status !== 200 && res.status !== 201){
                    return {
                        status: res.status,
                        error: true,
                        data: res.data
                    };
                }
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }

    async checkEmail(userId, key, token, email) {
        return await window.appData.$http.post('/public/check_mail/' + userId + '/' + key, {
            token: token,
            email: email,
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }


}