<template>
	<div class="p-fluid">
		<Dropdown v-model="innerValue" id="link_type" @change="updateValue(e)"
				  v-if="dropdownValues !== undefined"
				  :options="dropdownValues" optionLabel="name" placeholder=" -- " />
	</div>
</template>

<script>
/*
 	public const GENDER_MALE = 0;
    public const GENDER_FEMALE = 1;
    public const GENDER_NON_BINARY = 2;
    public const GENDER_OTHER = 3;
 */
export default {
	data() {
		return {
			linkTypesArray: [
				{
					name: "Homme",
					code: 0,
				},
				{
					name: "Femme",
					code: 1,
				},
				{
					name: "Non Binaire",
					code: 2,
				},
				{
					name: "Autre/Pas de réponse",
					code: 3,
				},
			],
			dropdownValues: undefined,
			innerValue: undefined,
		}
	},
	props: {
		value: {}
	},
	created() {

	},
	mounted() {
		const vm = this;
		this.innerValue = this.value;

		let arr = [];
		this.linkTypesArray.forEach(function(item){
			arr.push(item);
			if(item.code === vm.innerValue){
				vm.innerValue = item
			}
		});
		vm.dropdownValues = arr;
	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue.code)
		}
	}
}
</script>

<style scoped>

</style>
