export default {
    methods: {
       generateRandomColor(){
           return "#" + Math.floor(Math.random()*16777215).toString(16);
       },
        randomColorArrayOf(size){
           let arr = [];
            for (let i = 0; i < size; i++) {
                // do something
                arr.push(this.generateRandomColor())
            }

            return arr;
        },
        getImageBrightnessCss(el, _callback){
           let url = el.style.backgroundImage
               .replace('url(\'','').replace('url("','')
               .replace('\')','').replace('")','')
           ;
            this.getImageBrightness(url,_callback);
        },
        getImageBrightnessImg(el, _callback){
           this.getImageBrightness(el.attr("src"),_callback);
        },
        getImageBrightness(url,callback) {
            var img = document.createElement("img");
            img.src = url;
            img.crossOrigin = "Anonymous";
            img.style.display = "none";
            document.body.appendChild(img);

            var colorSum = 0;

            img.onload = function() {
                // create canvas
                var canvas = document.createElement("canvas");
                canvas.width = this.width;
                canvas.height = this.height;

                var ctx = canvas.getContext("2d");
                ctx.drawImage(this,0,0);

                var imageData = ctx.getImageData(0,0,canvas.width,canvas.height);
                var data = imageData.data;
                var r,g,b,avg;

                for(var x = 0, len = data.length; x < len; x+=4) {
                    r = data[x];
                    g = data[x+1];
                    b = data[x+2];

                    avg = Math.floor((r+g+b)/3);
                    colorSum += avg;
                }

                var brightness = Math.floor(colorSum / (this.width*this.height));

                img.parentNode.removeChild(img);
                canvas.remove();

                callback({
                    url: url,
                    b: brightness,
                });
            }
        }
    }
}
