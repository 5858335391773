<template>
	<div ref="main" class="p-fluid profile">
		<div v-if="profileData !== undefined" class="p-fluid">
			<div class="p-fluid p-header" :style="bgurl">
				<div class="p-header-overlay"></div>
				<div class="profile-snap">
					<img v-if="profileData.style.profile" :src="profileData.style.profile" alt="profile image" class="profile-img">
					<div class="p-profile-name">
						<h1>{{ profileData.profile.firstname }} {{ profileData.profile.lastname }}</h1>
						<h2>
							<span v-if="profileData.profile.poste !== ''">{{ profileData.profile.poste }},&nbsp;</span>
							<span v-if="profileData.profile.company !== ''">{{ profileData.profile.company }}</span>
						</h2>
						<div v-if="profileData.profile.addr.city !== '' || profileData.profile.addr.country !== ''" class="fast-geoloc">
							<i class="pi pi-map-marker"></i>
							<span v-if="profileData.profile.addr.city !== ''">{{ profileData.profile.addr.city }},&nbsp;</span>
							<span v-if="profileData.profile.addr.country !== ''">{{ profileData.profile.addr.country }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="fast-access button-group">
				<a v-if="profileData.profile.contact.phone1 !== ''" :href="'tel:' + profileData.profile.contact.phone1" class="button-group-item">
					Appeler
					<i class="pi pi-phone"></i>
				</a>
                <!--<a v-else class="button-group-item">
                    Message
                    <i class="pi pi-envelope"></i>
                </a>-->
				<a href="#" @click.prevent="dialogExchange = !dialogExchange" class="button-group-item">
					Coordonnées
					<i class="pi pi-id-card"></i>
				</a>
			</div>

            <div v-if="profileData.profile.status !== ''" class="greating-message">{{ profileData.profile.status }}</div>


            <h3 v-if="profileData.live.count > 0">Actuellement 🔴</h3>
            <div v-if="profileData.live.count > 0" class="contact-bloc">
                <div class="p-grid p-fluid p-jc-center">
                    <div v-for="ev in profileData.live.evts" :key="ev.id" class="p-col-12">
                        <div class="card app-card app-card-white bg-event">
                            <div class="fs-stage p-d-flex p-dir-row">
                                <div class="col-right">
                                    <div class="card-title">{{ ev.name }}</div>
                                    <div class="card-text">
                                        <div class="event-addr">
                                            <span v-if="ev.onSiteLocation !== ''" class="location">{{ ev.onSiteLocation }}<br></span>
                                            <span v-if="ev.addrStreet !== ''" class="location">{{ ev.addrStreet }}<br></span>
                                            <span v-if="ev.addrCity !== ''" class="location">{{ ev.addrPostalCode }} {{ ev.addrCity }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sd-stage event-time">
                                <div class="pill white-pill">
                                    <i class="pi pi-clock"></i>
                                    <small class="">{{ tmsRangeToHumanRange(ev.dateStart, ev.dateEnd) }}</small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

			<h3 class="no-pad">Réseaux sociaux</h3>
			<div class="social-link">
				<div class="p-grid p-fluid p-jc-center">

                    <div v-if="profileData.profile.urls.tw !== undefined && profileData.profile.urls.tw !== ''" class="p-col-3 social-item-container">
                        <a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           :href="profileData.profile.urls.tw">
							<div class="social-balloon twitter">
								<i class="pi pi-twitter"></i>
							</div>
							<small class="text-muted">twitter</small>
						</a>
					</div>


                    <div v-if="profileData.profile.urls.lkd !== undefined && profileData.profile.urls.lkd !== ''" class="p-col-3 social-item-container">
                        <a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           :href="profileData.profile.urls.lkd">
							<div class="social-balloon linkedin">
								<img src="/assets/icons/fi-xnsuxl-linkedin.svg" alt="linkedin logo">
							</div>
							<small class="text-muted">linkedin</small>
						</a>
					</div>

					<div v-if="profileData.profile.urls.fb !== undefined && profileData.profile.urls.fb !== ''" class="p-col-3 social-item-container">
						<a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           :href="profileData.profile.urls.fb">
							<div class="social-balloon facebook">
								<img src="/assets/icons/fi-xnsuxl-facebook.svg" alt="facebook logo">
							</div>
							<small class="text-muted">facebook</small>
						</a>
					</div>



                    <div v-if="profileData.profile.urls.ig !== undefined && profileData.profile.urls.ig !== ''" class="p-col-3 social-item-container">
                        <a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           :href="profileData.profile.urls.ig">
							<div class="social-balloon instagram">
								<img src="/assets/icons/fi-xnsuxl-instagram.svg" alt="instagram logo">
							</div>
							<small class="text-muted">instagram</small>
						</a>
					</div>

                    <div v-if="profileData.profile.urls.pnt !== undefined && profileData.profile.urls.pnt !== ''" class="p-col-3 social-item-container">
                        <a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           :href="profileData.profile.urls.pnt">
							<div class="social-balloon pinterest">
								<img src="/assets/icons/fi-xnsuxl-pinterest-alt.svg" alt="pinterest logo">
							</div>
							<small class="text-muted">pinterest</small>
						</a>
					</div>

                    <div v-if="profileData.profile.urls.wa !== undefined && profileData.profile.urls.wa !== ''" class="p-col-3 social-item-container">
                        <a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           :href="formatWhatsapp(profileData.profile.urls.wa)">
                            <div class="social-balloon whatsapp">
                                <img src="/assets/icons/iconmonstr-whatsapp-1.svg" alt="whatsapp logo">
                            </div>
                            <small class="text-muted">whatsapp</small>
                        </a>
                    </div>

                    <div v-if="profileData.profile.urls.tiktok !== undefined && profileData.profile.urls.tiktok !== ''" class="p-col-3 social-item-container">
                        <a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           :href="profileData.profile.urls.tiktok">
							<div class="social-balloon tiktok">
								<img src="/assets/icons/fi-tiktok.svg" alt="tiktok logo">
							</div>
							<small class="text-muted">tiktok</small>
						</a>
					</div>

					<div v-if="profileData.profile.urls.website !== undefined && profileData.profile.urls.website !== ''" class="p-col-3 social-item-container">
                        <a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           :href="profileData.profile.urls.website">
							<div class="social-balloon website">
								<i class="pi pi-link"></i>
							</div>
							<small style="white-space: nowrap;" class="text-muted">site-web</small>
						</a>
					</div>

                    <div class="p-col-3 social-item-container">
                        <a class="social-item" rel="noopener noreferrer"
                           target="_blank"
                           @click.prevent="qrOpen = true"
                           href="#">
                            <div class="social-balloon tiktok qrcode">
                                <img src="/assets/icons/qr-icon.svg" alt="qr logo">
                            </div>
                            <small style="text-align: center;" class="text-muted">QR code</small>
                        </a>
                    </div>


				</div>
			</div>

            <div v-if="qrOpen" class="qr-wrapper">
                <div @click.prevent="qrOpen = false" class="qr-overlay"></div>
                <div class="qr-container">
                    <QrcodeVue :size="sizeQr" level="H" :value="actualUrl"></QrcodeVue>
                    <p class="text-muted p-pt-4">toucher pour fermer</p>
                </div>
            </div>

			<h3>Contact</h3>
			<div class="contact-bloc">
                <div class="p-grid p-fluid">

                    <div v-if="profileData.meetings.enable !== undefined && profileData.meetings.enable" class="p-col-12">
                        <div class="card app-card bg-meeting">
                            <div class="fs-stage p-d-flex p-dir-row">
                                <div class="col-right">
                                    <div class="card-title">Rencontrons nous !</div>
                                    <div class="card-text">
                                        Prenez rendez-vous rapidement et simplement.
                                    </div>
                                </div>
                            </div>
                            <div class="sd-stage">
                                <a @click.prevent="dialogMeeting = !dialogMeeting" href="#" class="p-button button-white">Prendre rendez-vous</a>
                            </div>
                        </div>
                    </div>

                    <SlideUpDown :duration="250" v-model="dialogMeeting" class="calendar-block">
                        <div class="p-fluid">
                            <div class="b-calendar">
                                <Calendar v-model="selectedData" locale="fr_FR"
                                          :disabledDates="disabledDates"
                                          @month-change="monthChanged"
                                          @date-select="dayChanged"
                                          :minDate="minDateValue" dateFormat="dd.mm.yy" :inline="true" />
                            </div>

                            <div class="p-grid p-fluid p-nogutter p-mt-2">

                                <div class="p-col-6">
                                    <div class="p-field p-pt-1 p-pb-1 p-pr-1">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-calendar" />
                                            <InputText type="text" readonly v-model="pickedDateFormated" placeholder="Search" />
                                        </span>
                                    </div>
                                </div>
                                <div class="p-col-6">
                                    <div class="p-field p-pt-1 p-pb-1 p-pl-1">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-clock" />
                                            <TimeSelector
                                                    :slots="availableTimeSlots"
                                                    @input="updateMeetingTimeslot"
                                                    :value="meetingTimeslotSelected" ></TimeSelector>
                                        </span>

                                    </div>
                                </div>

                                <div class="p-col-12">
                                    <div class="p-field">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-envelope" />
                                            <InputText type="text"
                                                       v-model="emailVisitor" placeholder="Votre e-mail" />
                                        </span>
                                    </div>
                                </div>

                                <div class="p-col-12">
                                    <div class="p-fluid p-button-w100">
                                        <Button :disabled="readyToBook" label="Créer le rendez-vous"
                                                @click.prevent="openBookDialog"
                                                class="b-button p-button b-button-yellow"></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SlideUpDown>

                    <SlideUpDown :duration="250" v-model="dialogMeetingReverse" class="p-fluid p-grid p-contact-detail">

                        <div v-if="profileData.profile.contact.phone1 !== undefined && profileData.profile.contact.phone1 !== ''" class="p-col-12">
                            <div class="card middle-fs border-one-line micro-pad p-d-flex p-justify-between p-align-center">
                                <div class="left-ico">
                                <span class="round-pill">
                                    <i class="pi pi-mobile"></i>
                                </span>
                                </div>
                                <div class="event-addr right-event">
                                    <a :href="'tel:' + profileData.profile.contact.phone1" class="location">{{ profileData.profile.contact.phone1 }}</a>
                                </div>
                            </div>
                        </div>

                        <div v-if="profileData.profile.contact.phone2 !== undefined && profileData.profile.contact.phone2 !== ''" class="p-col-12">
                            <div class="card middle-fs border-one-line micro-pad p-d-flex p-justify-between p-align-center">
                                <div class="left-ico">
                                <span class="round-pill">
                                    <i class="pi pi-phone"></i>
                                </span>
                                </div>
                                <div class="event-addr right-event">
                                    <a :href="'tel:' + profileData.profile.contact.phone2" class="location">{{ profileData.profile.contact.phone2 }}</a>
                                </div>
                            </div>
                        </div>

                        <div v-if="profileData.profile.contact.email !== undefined && profileData.profile.contact.email !== ''" class="p-col-12">
                            <div class="card middle-fs border-one-line micro-pad p-d-flex p-justify-between p-align-center">
                                <div class="left-ico">
                                <span class="round-pill">
                                    <i class="pi pi-envelope"></i>
                                </span>
                                </div>
                                <div class="event-addr right-event">
                                    <a :href="'mailto:' + profileData.profile.contact.email" class="location">{{ profileData.profile.contact.email }}</a>
                                </div>
                            </div>
                        </div>

                    </SlideUpDown>

                    <Dialog header="Prise de rendez-vous"
                            :closable="false"
                            v-model:visible="dialogValidateMeeting"
                            :breakpoints="{'960px': '100vw'}" :style="{width: '90vw'}" :modal="true">
                        <MeetingValidator :targetSlot="meetingTimeslotSelected" @errored="dialogValidateMeeting = false"
                                          :uid="userId" :ukey="key" @close="closeEmited"
                                          :email="emailVisitor" :profile="profileData"></MeetingValidator>
                    </Dialog>



                    <div class="p-col-12">
                        <div class="card app-card app-card-white bg-exchange p-d-flex p-dir-col p-mt-3">
                            <div class="fs-stage p-d-flex p-dir-row p-text-right">
                                <div class="col-right">
                                    <div class="card-title">Échangeons nos coordonnées !</div>
                                    <div class="card-text">
                                        Accédez à toutes mes informations de contacts rapidement.
                                    </div>
                                </div>
                            </div>
                            <div class="sd-stage p-text-right">
                                <a @click.prevent="dialogExchange = !dialogExchange" href="#" class="p-button button-dark">Échanger maintenant</a>
                            </div>
                        </div>
                    </div>

                    <Dialog header="Echange de coordonnées"
                            :closable="false"
                            v-model:visible="dialogExchange"
                            :breakpoints="{'960px': '100vw'}" :style="{width: '90vw'}" :modal="true">
                        <ContactExchange @errored="dialogValidateMeeting = false"
                                          :uid="userId" :ukey="key" @close="dialogExchange = false"
                                          :profile="profileData"></ContactExchange>
                    </Dialog>

                </div>
            </div>


            <h3>Adresse & horaires</h3>
            <div class="contact-bloc">
                <div class="p-grid p-fluid p-jc-center">
                    <div v-if="profileData.profile.addr.city !== undefined && profileData.profile.addr.city !== ''" class="p-col-12">
                        <div @click.prevent="dialogMap = !dialogMap" class="card middle-fs micro-pad p-d-flex p-justify-between p-align-center">
                            <i class="pi pi-chevron-right absolute-right"></i>
                            <div class="left-ico">
                                <span class="round-pill">
                                    <i class="pi pi-map-marker"></i>
                                </span>
                            </div>
                            <div class="event-addr right-event">
                                <span v-if="profileData.profile.addr.addr1 !== ''" class="location">{{ profileData.profile.addr.addr1 }}<br></span>
                                <span v-if="profileData.profile.addr.addr2 !== ''" class="location">{{ profileData.profile.addr.addr2 }}<br></span>
                                <span v-if="profileData.profile.addr.addr3 !== ''" class="location">{{ profileData.profile.addr.addr3 }}<br></span>
                                <span v-if="profileData.profile.addr.city !== ''" class="location">{{ profileData.profile.addr.postal_code }} {{ profileData.profile.addr.city }}</span>
                            </div>
                        </div>
                    </div>

                    <SlideUpDown :duration="250" v-model="dialogMap" class="social-link">
                        <div class="p-grid p-nogutter p-fluid p-jc-center">

                            <div v-if="wazeurl !== undefined" class="p-col-3 social-item-container p-mr-3">
                                <a class="social-item" rel="noopener noreferrer"
                                   target="_blank"
                                   :href="wazeurl">
                                    <div class="social-balloon waze">
                                        <img src="/assets/icons/waze.svg" alt="tiktok logo">
                                    </div>
                                    <small class="text-muted">waze</small>
                                </a>
                            </div>

                            <div v-if="openMap !== undefined" class="p-col-3 social-item-container">
                                <a class="social-item" rel="noopener noreferrer"
                                   target="_blank"
                                   :href="openMap">
                                    <div class="social-balloon maps">
                                        <i class="pi pi-map"></i>
                                    </div>
                                    <small class="text-muted">maps</small>
                                </a>
                            </div>
                        </div>
                    </SlideUpDown>

                    <div v-if="getHorr !== []" class="p-col-12">
                        <div class="card middle-fs micro-pad p-d-flex p-justify-between p-align-center">
                            <div class="left-ico">
                                <span class="round-pill">
                                    <i class="pi pi-clock"></i>
                                </span>
                            </div>
                            <div class="event-addr right-event">
                                <div v-for="b, k in getHorr" :key="'bh_' + b.id" class="location">
                                    <b class="day">{{ k }} :</b> <span v-for="h, i in b" :key="'h_' + i" class="hours">{{ h.start }} - {{ h.end }}<i v-if="i < b.length - 1">,&nbsp;</i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h3>Présentation</h3>
            <div class="presentation-bloc">
                <div v-html="getHTMLcontent" class="content"></div>

                <div v-if="profileData.profile.links !== undefined" class="link-bloc">
                    <div v-for="link, li in profileData.profile.links" :key="'link_' + li" class="p-fluid link-wrapper">
                        <div v-bind:class="{'type-default': link.type !== 'link_image',
                         'type-image': link.type === 'link_image', 'thumbnail': link.type === 'link_image' }"
                             :style="computeStyle(link)"
                             class="link-item">
                            <div class="link-item-overlay"></div>
                            <a :href="computeUrl(link)" :title="link.alt" class="targets--a"
                               target="_blank" rel="noreferrer noopener">{{ link.label }}</a>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import SocialService from "../service/SocialService";
import showdown from 'showdown';
import calendarMixin from "../mixins/calendarMixin";
import colorMixin from "../mixins/colorMixin";
import SlideUpDown from 'vue3-slide-up-down';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import CalendarService from "../service/CalendarService";
import TimeSelector from '../components/app/calendar/TimeSelector.vue';
import MeetingValidator from '../components/app/calendar/MeetingValidator.vue';
import ContactExchange from '../components/app/crm/ContactExchange.vue';
/**
 * @see https://www.npmjs.com/package/qrcode.vue
 */
import QrcodeVue from 'qrcode.vue'

export default {
	data(){
        let d = new Date();
		return {
			data: undefined,
			userId: undefined,
			key: undefined,
			profileData: undefined,
            dialogMap: false,
            dialogMeeting: false,
            selectedData: d,
            selectedMonth: d.getMonth(),
            meetingsSlotsData: undefined,
            loadingCalendar: false,
            meetingTimeslotSelected: undefined,
            emailVisitor: '',
            dialogValidateMeeting: false,
            dialogExchange: false,
            qrOpen: false,
		}
	},
	created(){
		this.social = new SocialService();
		this.calendar = new CalendarService();
	},
	mounted(){
		const params = this.$router.currentRoute._value.params;
		this.userId = params.id;
		this.key = params.key;

		if(this.userId === undefined || this.key === undefined){
			//done throw error
			this.err404();
			return;
		}

		this.loadData();
	},
	methods: {
        formatWhatsapp(in_){
            return 'https://wa.me/' + in_.replace(/\+/i,'').replace(/\s/i,'');
        },
	    closeEmited(e){
	        console.log('closeEmited',e);
	        if(e){
	            this.dialogMeeting = false;
                this.meetingTimeslotSelected = undefined;
            }

            this.dialogValidateMeeting = false
        },
        getScript(url, callback, tag,  timeout ){
            let timeoutIdx;
            tag = tag || 'script';
            const scriptTag = document.createElement(tag);

            timeout = timeout || 100;

            if(tag === 'script'){
                scriptTag.type = 'text/' + (url.type || 'javascript');
                scriptTag.src = url.src || url;
                scriptTag.async = false;
            } else {
                scriptTag.type = "text/css";
                scriptTag.rel = "stylesheet";
                scriptTag.href = url.src || url;
            }

            scriptTag.onreadystatechange = () => {
                // this code handles two scenarios, whether called by onload or onreadystatechange
                const state = scriptTag.readyState;

                clearTimeout(timeoutIdx);

                if (!callback.done && (!state || /loaded|complete/.test(state))) {
                    callback.done = true;
                    callback();
                    scriptTag.onreadystatechange = scriptTag.onload = null
                }
            };

            if(tag === 'script'){
                document.body.appendChild(scriptTag);
            } else {
                document.getElementsByTagName('head')[0].appendChild(scriptTag);
            }


            // You can't catch JSONP Errors, because it's handled by the script tag
            // one way is to use a timeout
            timeoutIdx = setTimeout(() => {
                callback.done = true;
                callback();
                scriptTag.onreadystatechange = scriptTag.onload = null
            }, timeout)
        },
        openBookDialog(){
            this.dialogValidateMeeting = true;
        },
        updateMeetingTimeslot(e){
            this.meetingTimeslotSelected = e;
            console.log(e,this.meetingTimeslotSelected);
        },
        dayChanged(e){
            this.meetingTimeslotSelected = undefined;
            console.log(e,this.selectedData);
        },
	    monthChanged(e){
            this.meetingTimeslotSelected = undefined;
            this.selectedMonth = e.month;
            this.loadOneMonthTimeSlot();
        },
        loadOneMonthTimeSlot(){
	        this.loadingCalendar = true;
	        let m = new moment();
	        m = m.month(this.selectedMonth).startOf('month');
	        let e = m.clone().endOf('month');

	        this.calendar.getTimeslots(this.userId, this.key, m.toISOString(), e.toISOString())
                .then(resp => {
                    this.meetingsSlotsData = resp.data.tsm;
                })
                .catch(err => {
                    this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
                })
                .finally(() => {
                    this.loadingCalendar = false;
                })
            ;
        },
	    bgCheck(){
	        try {
                let linkItems = this.$refs.main.querySelectorAll('.link-item.thumbnail');
                linkItems.forEach(el => {
                    this.getImageBrightnessCss(el, (evt) => {
                        if(evt.b > 128){
                            el.classList.add('bg--bright')
                        } else {
                            el.classList.add('bg--dark')
                        }
                    })
                })
            } catch (e) {
                console.warn('profilePage.bgcheck',e);
            }
        },
        computeUrl(link){
            if(link.type !== 'link_file'){
                return link.url;
            }

            return link.file_url;
        },
        computeStyle(link){
            if(link.type !== 'link_image'){
                return '';
            }

            return 'background-image:url("' + link.bg_url + '");';
        },
		err404() {
            window.location.replace("http://www.freecard.fr");
		},
		loadData(){
			this.social.getSocialProfile(this.userId, this.key)
					.then(resp => {
						try {
							this.profileData = resp.data.data;
							if(this.profileData.style.stylesheet !== undefined){
							    this.getScript(window.appData.env.staticBaseUrl + '/themes/css/' + this.profileData.style.stylesheet,
                                    () => {
							        console.log('loaded styles');
                                    }, 'link');
                            }
						} catch (e) {
							this.err404();
						}
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						this.err404();
					})
					.finally(() => {
						window.appData.$main.loadingState(false);
						this.bgCheck();
						this.loadOneMonthTimeSlot();
					})
			;
		},

	},
	computed: {
	    sizeQr(){
	        if( window.screen.width >  window.screen.height){
                return window.screen.height * 0.80;
            }
	        return window.screen.width * 0.80;
        },
        actualUrl(){
            return window.location.href;
        },
	    readyToBook(){
	        let isDisabled = true;
            let reg = new RegExp('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$', 'i');
            if(
                this.meetingTimeslotSelected !== undefined &&
                (this.emailVisitor !== '' && reg.test(this.emailVisitor))
            ){
                isDisabled = false;
            }

	        //this.emailVisitor
	        return isDisabled;
        },
        dialogMeetingReverse(){
            return !this.dialogMeeting;
        },
	    availableTimeSlots(){
            if(this.meetingsSlotsData === undefined){
                return [];
            } else {
                let arr = [];
                let d = moment(this.selectedData);
                let key = d.format('YYYY-MM-DD');
                if(this.meetingsSlotsData[key]){
                    for(let i = 0; i < this.meetingsSlotsData[key].slots.length; i++){
                        let item = this.meetingsSlotsData[key].slots[i];
                        if(item.available === true){
                            item.name = item.start + ' - ' + item.end;
                            arr.push(item);
                        }
                    }
                }

                return arr;
            }
        },
        pickedDateFormated(){
            return this.isoStrToDayDate(this.selectedData);
        },
	    disabledDates(){
	        if(this.meetingsSlotsData === undefined){
	            return [];
            } else {
	            let arr = [];

	            // check all day
	            for(const prop in this.meetingsSlotsData){
	                let hasDate = false;
	                if(this.meetingsSlotsData[prop].slots !== undefined){
	                    // check all slots
	                    for(let i = 0; i < this.meetingsSlotsData[prop].slots.length; i++){
	                        let item = this.meetingsSlotsData[prop].slots[i];
	                        if(item.available === true){
	                            hasDate = true;
	                            break;
                            }
                        }
                    }

	                if(hasDate === false){
	                    let d = moment(prop);

	                    arr.push(d.toDate());
                    }
                }


	            return arr;
            }

        },
        minDateValue(){
            return new Date();
        },
	    openMap(){
            if(this.profileData === undefined) {
                return undefined;
            }
            if(this.profileData.profile.addr.enable !== true || this.profileData.profile.addr.geo === undefined
                || this.profileData.profile.addr.geo.lat === undefined || this.profileData.profile.addr.geo.lat === ''){
                return undefined;
            }

	        let strIos = 'maps://maps.google.com/maps?daddr={lat},{lon}&amp;ll='
                .replace('{lat}', this.profileData.profile.addr.geo.lat)
                .replace('{lon}', this.profileData.profile.addr.geo.lon);
	        let strAndroid = 'https://maps.google.com/maps?daddr={lat},{lon}&amp;ll='
                .replace('{lat}', this.profileData.profile.addr.geo.lat)
                .replace('{lon}', this.profileData.profile.addr.geo.lon);
            if /* if we're on iOS, open in Apple Maps */
            ((navigator.platform.indexOf("iPhone") != -1) ||
                (navigator.platform.indexOf("iPad") != -1) ||
                (navigator.platform.indexOf("iPod") != -1))
            {
                return strIos;
            }
            else /* else use Google */
            {
                return strAndroid;
            }
        },
	    wazeurl(){
            let baseurl = 'https://www.waze.com/ul?ll={lat}%2C{lon}&navigate=yes&zoom=10&utm_campaign=freecard.fr';
            if(this.profileData === undefined) {
                return undefined;
            }
            if(this.profileData.profile.addr.enable !== true){
                return undefined;
            }

            if(this.profileData.profile.addr.geo === undefined
            || this.profileData.profile.addr.geo.lat === undefined || this.profileData.profile.addr.geo.lat === ''){
                baseurl = 'https://www.waze.com/ul?q={addr}&navigate=yes&zoom=10&utm_campaign=freecard.fr';
                return baseurl.replace('{lat}', this.profileData.profile.addr.addr1 + ' ' + this.profileData.profile.addr.addr2
                    + ' ' + this.profileData.profile.addr.addr3 + ' ' + this.profileData.profile.addr.postal_code + ' '
                 + this.profileData.profile.addr.city);
            }

            return baseurl.replace('{lat}', this.profileData.profile.addr.geo.lat)
                .replace('{lon}', this.profileData.profile.addr.geo.lon);
        },
		bgurl(){
			let base = 'background-image:url(\'';

			if(this.profileData === undefined || this.profileData.style.bg === undefined){
				return ''; // todo set default value here
			} else {
				return base + this.profileData.style.bg + '\');';
			}
		},
        getHTMLcontent(){
            if(this.profileData === undefined || this.profileData.profile.presentation === undefined){
                return '';
            } else {
                /**
                 * @see https://github.com/showdownjs/showdown
                 * @type {Converter}
                 */
                let converter = new showdown.Converter();
                return converter.makeHtml(this.profileData.profile.presentation);
            }
        },
        getHorr(){
		    let arr = {};
            if(this.profileData === undefined || this.profileData.meetings.bhs === undefined){
                return arr;
            }

            this.profileData.meetings.bhs.forEach(bh => {
                if(bh.mon === true){
                    if(arr['Lundi'] === undefined){
                        arr['Lundi'] = [];
                    }
                    arr['Lundi'].push({
                        start: bh.start,
                        end: bh.end,
                    })
                }

                if(bh.tue === true){
                    if(arr['Mardi'] === undefined){
                        arr['Mardi'] = [];
                    }
                    arr['Mardi'].push({
                        start: bh.start,
                        end: bh.end,
                    })
                }

                if(bh.wed === true){
                    if(arr['mercredi'] === undefined){
                        arr['mercredi'] = [];
                    }
                    arr['mercredi'].push({
                        start: bh.start,
                        end: bh.end,
                    })
                }

                if(bh.thu === true){
                    if(arr['Jeudi'] === undefined){
                        arr['Jeudi'] = [];
                    }
                    arr['Jeudi'].push({
                        start: bh.start,
                        end: bh.end,
                    })
                }

                if(bh.fri === true){
                    if(arr['Vendredi'] === undefined){
                        arr['Vendredi'] = [];
                    }
                    arr['Vendredi'].push({
                        start: bh.start,
                        end: bh.end,
                    })
                }

                if(bh.sat === true){
                    if(arr['Samedi'] === undefined){
                        arr['Samedi'] = [];
                    }
                    arr['Samedi'].push({
                        start: bh.start,
                        end: bh.end,
                    })
                }

                if(bh.sun === true){
                    if(arr['Dimanche'] === undefined){
                        arr['Dimanche'] = [];
                    }
                    arr['Dimanche'].push({
                        start: bh.start,
                        end: bh.end,
                    })
                }


            });


		    return arr;
        }
	},
	mixins: [calendarMixin, colorMixin],
	components: {
        SlideUpDown,
        Calendar,
        TimeSelector,
        MeetingValidator,
        ContactExchange,
        QrcodeVue,
	}
}
</script>

<style scoped>

</style>
