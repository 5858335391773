<template>
	<div class="p-fluid">
		<Dropdown v-model="innerValue" @change="updateValue"
				  :options="slots" optionLabel="name" placeholder="--:--" />
	</div>
</template>

<script>
export default {
	data(){
		return {
			innerValue: undefined,
		}
	},
	props: {
		value: {},
		slots: {},
	},
	created(){
		this.innerValue = this.value;
	},
	mounted(){

	},
	methods: {
		updateValue() {
			this.$emit('input', this.innerValue)
		}
	},
	computed: {

	},
	mixins: [],
	components: {

	}
}
</script>

<style scoped>

</style>
