
export default class SocialService {
    async getSocialProfile(userId, key) {
        return await window.appData.$http.get('/public/profile/' + userId + '/' + key, {
            params: {
                lang: navigator.language,
            }
        })
            .then(res => {
                return {
                    status: res.status,
                    error: false,
                    data: res.data
                };
            })
            .catch(err => {
                console.error(err);
                return {
                    error: true,
                    data: err.response.data,
                    message: err.message
                };
            })
            ;
    }
}